<template>
  <div class="bigPage">
    <div class="page" @click="openApp">
      <div class="header">
<!--        <div @click="openApp" style="position: fixed;right: 0;top: 20%;">
          <div class="open21du">
            <span>打开21°网上商城</span><img src="../../assets/images/icon_right.png" alt="">
          </div>
        </div>-->
<!--        <a id="downAndroid" href="javascript:void(0);" download="javascript:void(0);"></a>-->
      </div>
    </div>
    <div class="mask" id="mask">
      <img src="https://cdn.zhijingwuxian.com/1656743361000.png" alt="">
    </div>
  </div>
</template>

<script>
import { GetCouponDownUrl } from "@/api/bill";
import qs from "qs";
export default {
  name: "shareCoupon",
  data(){
    return{
      imgUrl:this.GLOBAL.imgUrl,
      img:'',
      coupon_code: "",
      oppo_url: null,
      default_url: null
    }
  },
  mounted: function () {
    document.title = '送您一张内部优惠券，请到APP下单使用'
    this.coupon_code = this.$route.query.coupon_code;
  },
  methods: {
    openApp(){
      var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //在微信中打开
        document.getElementById("mask").style.display="block";
      }else{
        var u = navigator.userAgent
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        if (isiOS) {
          //ios的scheme协议
          window.location.href = 'DegreeShop://pageCouponHome_'+this.coupon_code
          setTimeout(function() {
            let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden || window.document.webkitHidden
            if (typeof hidden == "undefined" || hidden == false) {
              //App store下载地址
              window.top.location.href = "https://apps.apple.com/cn/app/id1540523445";
            }
          }, 2000);
        }
        if(isAndroid){
          //安卓app的scheme协议
          var hasApp = true;
          var t = 1000;
          let isOppo = ua.indexOf("oppo") != -1 || ua.indexOf("heytapbrowser") != -1;
          let isHuawei = ua.indexOf("huawei") != -1;
          let isMi = ua.indexOf("redmi") != -1 || ua.indexOf("xiaomi") != -1;
          let timer = setTimeout(function () {
            if (!hasApp) {
              GetCouponDownUrl().then((res) => {
                let {code, data, message} = res;
                if (code != 0) return this.$toast(message || "请求错误");
                  //下载地址
                  if (isOppo ) {
                    window.location.href = data.oppo_url+'?time='+Date.now()
                  } else if (isHuawei) {
                    window.location.href  = "appmarket://details?id=com.wangrui.a21du";
                  }else if (isMi) {
                    window.location.href  = "mimarket://details?id=com.wangrui.a21du";
                  } else {
                    window.location.href = data.default_url
                  }
              })
            }
          }, 2000);
          var t1 = Date.now();
          setTimeout(function () {
            // t的时间就是 APP 启动的时间，若 APP 启动了，再次返回页面时 t2 这行代码执行，hasApp 即为 true。反之若APP没有启动即为 false
            var t2 = Date.now();
            hasApp = !(!t1 || t2 - t1 < t + 150);
          }, t);
          if(isOppo){
            window.location.href = 'wangrui://pageCouponHome_'+this.coupon_code+'?time='+Date.now();
          }else{
            window.location.href = 'wangrui://pageCouponHome_'+this.coupon_code
          }
        }
      }

    },
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .bigPage {
    width: 320px;
    margin: 0 auto;
  }
}
.page{
  background-image: url("../../assets/images/coupon_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
.price{
  transform: rotate(-20deg);
  position: absolute;
  top: calc( 100% - 68%);
  left: calc( 100% - 60%);
  right: 0;
  bottom: 0;
  color: #E95A44;
}
.price span{
  font-size: 46px;
  font-weight: 700;
}
.price b{
  font-size: 76px;
  font-weight: 900;
}
.header{
  .head{
    text-align: center;
    padding: 10px;
    .headImg{
      width: 100%;
    }
  }
  .goodTitBox{
    padding: 20px 16px;
    .title1{
      display: flex;
      line-height: 28px;
      .nowPrice{
        margin: 0;
        color: #FF3737;
        font-size: 28px;
        font-weight: 600;
        i{
          font-size: 18px;
          font-style: normal;
          font-family: PingFangSC-Medium;
          font-weight: 600;
        }
        span{
          color: #BBBBBB;
          font-size: 14px;
        }
      }
      .beforePrice{
        font-size: 14px;
        text-decoration: line-through;
        color: #BBBBBB;
        margin: 5px 0 0 16px;
      }
    }
    .title2{
      font-size: 18px;
      font-weight: 600;
      color: #111111;
      margin-top: 16px;
    }
    .title3{
      font-size: 14px;
      color: #909090;
      margin-top: 12px;
    }
    .title4{
      display: flex;
      flex-wrap:wrap;
      font-size: 13px;
      color: #909090;
      margin-top: 16px;
      p{

        margin: 5px 0;
        img{
          width: 18px;
          height: 18px;
          vertical-align: text-top;
        }
        span{
          margin-left: 4px;
        }
      }
      p:not(:nth-child(4n-1)){
        margin-right: 16px;
      }
    }
  }
}
.content{
  background-color: #F3F3F3;
  padding: 8px 12px;
  .store{
    background-color: #FFF;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    .storeImg{
      display: block;
      width: 30px;
      height: 30px;
      padding: 2px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #D8D8D8;
      img{
        width: 27px;
        height: 27px;
      }
    }
    .storeName{
      font-size: 13px;
      color: #000000;
      margin-left: 12px;
      display: block;
      margin-top: 9px;
    }
  }
  .info{
    margin-top: 8px;
    background-color: #FFF;
    padding: 16px 12px;
    border-radius: 12px;
    .canshu{
      font-size: 14px;
      font-weight: 600;
      color: #373737;
    }
    .canshuInfo{
      display: flex;
      margin-top: 20px;
      span{
        display: block;
        min-width: 100px;
        max-width: 200px;
        font-size: 14px;
        font-weight: 400;
        color: #BBBBBB;
      }
      b{
        display: block;
        min-width: 100px;
        max-width: 150px;
        font-size: 14px;
        font-weight: 400;
        color: #373737;
      }
    }
  }
}
.tips{
  background-color: #FFF;
  padding: 16px;
  font-size: 14px;
  color: #F30000;
  p{
    margin: 0;
    line-height: 26px;
  }
}
.footer{
  width: 100%;
  margin: 0 auto;
  p{
    span{
      background-color: #FFF !important;
      padding: 16px !important;
      font-size: 14px !important;
      color: #F30000 !important;
    }
    img{
      margin: 0 auto !important;
    }
  }
  img{
    width: 100%;
    margin: 0 auto;
  }
}
.open21du{
  background-color:#02B2FF;
  line-height:40px;
  height:40px;
  padding: 0 8px 0 16px;
  color: #FFF;
  font-size:15px;
  border-radius: 100px 0px 0px 100px;
  img{
    width:14px;
    vertical-align:middle;
    margin-bottom:4px;
    margin-left: 3px;
  }
}
.mask{display: none;position: fixed;width: 100%;height: 100%;left: 0;right: 0;top: 0;bottom: 0;background: rgba(0,0,0,.8);z-index: 9;}
.mask img{width: 100%;}
</style>